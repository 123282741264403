<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app clipped-left>
            <v-toolbar-title style="font-size: 24px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;" v-if="!isEnterpriseBranding">
                    <!-- <span class="green--text text--darken-1">Liberty</span><span class="green--text text--lighten-2">DNS</span> -->
                    <v-img alt="Unicorn Springs" src="@/assets/logo/unicornsprings/UnicornSprings_light_264x48.png" class="mr-1 float-left" width="196" height="36" fit="inside"></v-img>
                </router-link>
                <router-link :to="{ name: 'organization-dashboard', params: { organizationId: organization.id } }" style="text-decoration: none;" v-if="isEnterpriseBranding">
                    <span class="green--text text--darken-2">{{ organization.name }}</span> <!-- TODO: for enterprise branding, show the enteprise logo here, not the account name -->
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="green"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <template v-if="isAuthenticated && organization">
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="green--text text--darken-2">
                            <font-awesome-icon :icon="['fas', 'folder-open']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <!-- TODO: add query parameters for new, unfulfilled orders that need to be processed, and change label to 'New Orders'; from that page admin can change search parameters to see any orders -->
                        <v-list-item :to="{ name: 'organization-search-order', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'inbox']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Orders
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'organization-search-invoice', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <!-- TODO: the file-alt, file-invoice, and file-invoice-dollar icon all look very 'busy' when small; maybe use paper-plane instead? -->
                                <font-awesome-icon :icon="['fas', 'paper-plane']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Invoices
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'organization-search-payment', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'gem']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Payments
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- NOTE: removed refunds from here because the menu is getting too long... admin can go to payments, then to refunds, or go to organization dashboard and get to this directly from the full list of collections -->
                        <v-list-item :to="{ name: 'organization-search-account', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'file-alt']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Accounts
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'organization-search-user', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'file-user']" class="green--text text--darken-2" fixed-width></font-awesome-icon> <!-- or fas address-book -->
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Users
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- NOTE: removed account services from here because the menu is getting too long... admin can go to an account, then look at that account's services, or go to organization dashboard and get to this directly from the full list of collections -->
                        <!-- <v-list-item :to="{ name: 'organization-search-service', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'concierge-bell']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Services
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-divider/>
                        <v-list-item :to="{ name: 'organization-search-product', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'cube']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Products
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- NOTE: removed product groups from here because the menu is getting too long... admin can go to products, then to product groups, or go to organization dashboard and get to this directly from the full list of collections -->
                        <!-- <v-list-item :to="{ name: 'organization-search-product-group', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'cubes']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Product Groups
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item :to="{ name: 'organization-search-admin', params: { brandprofile: this.$route.params.brandprofile, organizationId: this.$route.params.organizationId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'user-friends']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Administrators
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <TextButton class="text-headline" style="font-size: 1.1em; line-height: 1.1em;" @click="navigateToOrganizationDashboard">
                    <span v-html="organizationNameNbsp"></span>
                    <!-- {{ organizationNameNbsp }} -->
                </TextButton>
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="green--text text--darken-2">
                            <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item :to="{ name: 'organization-create-product', params: { organizationId: $route.params.organizationId } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'cube']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    New Product
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- services are now defined using product table so this isn't needed ; TODO: delete the 'organization-create-service' route and corresponding .vue file -->
                        <!-- <v-list-item :to="{ name: 'organization-create-service', params: { organizationId: $route.params.organizationId } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'concierge-bell']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    New Service
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <!-- <v-list-item :to="{ name: 'organization-create-customer', params: { organizationId: $route.params.organizationId } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'folder']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    New Customer
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <!-- TODO: new customer, new order, new invoice, ... -->
                    </v-list>
                </v-menu>
            </template>

            <v-btn icon color="green darken-2" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width></font-awesome-icon>
            </v-btn>
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon color="green darken-2" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width></font-awesome-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a :href="contactURL" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="green" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y open-on-click open-on-hover close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                <font-awesome-icon right class="green--text" :icon="['fas', 'bars']" fixed-width></font-awesome-icon>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="green">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <v-btn outlined color="green darken-2" v-if="!isAuthenticated && this.$route.name !== 'login'" @click="login">
                Sign in
            </v-btn>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="green--text text--darken-2">
                        <Avatar :attr="mainProfile" :size="28"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-if="user">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-organization-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Organizations
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" class="green--text text--darken-2" fixed-width></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <div>
                        <v-tabs vertical slider-color="green darken-2" class="menutabs">
                            <v-tab :to="{ name: 'user-dashboard' }" v-show="false"></v-tab>
                            <v-tab :to="{ name: 'profile' }">Profile</v-tab>
                            <v-tab :to="{ name: 'user-organization-list' }">Organizations</v-tab>
                            <v-tab :to="{ name: 'user-dashboard' }">Dashboard</v-tab>
                            <v-tab :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">Service admin</v-tab>
                            <v-divider></v-divider>
                            <v-tab @click="onLogout">Logout</v-tab>
                        </v-tabs>
                    </div> -->
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        Avatar,
        TextButton,
    },

    data: () => ({
        helpMenu: false,
    }),

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            organization: (state) => state.organization,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        contactURL() {
            const websiteURL = new URL(process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://unicornsprings.com');
            websiteURL.pathname = '/contact/';
            return websiteURL.toString();
        },
        isEnterpriseBranding() {
            return false; // TODO: when the selected account has this feature enabled, then we should detect and return true here, so we'll show their logo on the left side of the navbar instead of the Unicorn Springs logo
        },
        organizationNameNbsp() {
            // the intent is to replace spaces with &nbsp; but because we have to use v-html to render this, we ALSO need to escape angle brackets and quotes to prevent XSS attacks with the account name itself (because user can edit the account name, and then admins can look at it, so the attack would be an authorized user trying to take over an admin's session)
            return this.organization?.name
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/, '&apos;')
                .replace(/ /g, '&nbsp;');
        },
    },

    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            this.$router.push({ name: 'login', query: { next: fullPath } });
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
        navigateToOrganizationDashboard() {
            this.$router.push({ name: 'organization-dashboard', params: { organizationId: this.organization.id }, query: { t: Date.now() } });
        },
    },

    mounted() {
        // console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        // if (this.$route.params.realmId) {
        //     console.log(`navabar realm ${this.$route.params.realmId}`);
        // } else {
        //     console.log('navbar no realm');
        // }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
