<template>
    <span :style="combinedStyle" @click="$emit('click', $event.target ? $event.target.value : false)">
        <slot name="icon-before">
            <font-awesome-icon :icon="iconBefore" fixed-width v-if="iconBefore" class="mr-2"></font-awesome-icon>
        </slot>
        <slot></slot>
        <slot name="icon-after">
            <font-awesome-icon :icon="iconAfter" fixed-width v-if="iconAfter" class="ml-2"></font-awesome-icon>
        </slot>
    </span>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: null,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        iconBefore: {
            type: Array,
            default: null,
        },
        iconAfter: {
            type: Array,
            default: null,
        },
    },
    computed: {
        colorStyle() {
            if (this.color) {
                return `color: ${this.color};`;
            }
            return '';
        },
        underlineStyle() {
            if (this.underline) {
                return 'text-decoration: underline;';
            }
            return '';
        },
        combinedStyle() {
            const defaultStyle = 'cursor: pointer;';
            return `${defaultStyle} ${this.underlineStyle} ${this.colorStyle}`;
        },
    },
};
</script>
